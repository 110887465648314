<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Prueba de entrega')"
    :ok-title="$t('Cerrar')"
    size="lg"
    ok-variant="outline-secondary"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
    v-if="proof"
  >
    <b-container>
      <b-row align-v="center">
        <b-col>
          <b-row v-for="(proof, index) in infoProof" :key="index" align-v="center" class="p-1">
            <media-info v-bind="proof"></media-info>
          </b-row>
        </b-col>
        <b-col>
          <b-row v-for="(proof, index) in imgsProof" :key="index" align-v="center" class="p-1">
            <b-link
              :href="proof"
              target="_black"
              style="cursor: zoom-in"
            >
              <b-img thumbnail fluid :src="proof" :alt="'image'+index"/>
            </b-link>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import { BLink, BContainer } from 'bootstrap-vue'
export default {
  name: 'proof-modal',
  components: { BLink, BContainer },
  inheritAttrs: false,
  props: {
    proof: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      infoProof: [],
      imgsProof: []
    }
  },
  watch: {
    proof () {
      this.generateInfoProof(this.proof)
    }
  },
  mounted () {
    this.generateInfoProof(this.proof)
  },
  methods: {
    generateInfoProof (proof) {
      this.infoProof = [
        {
          icon: 'CreditCardIcon',
          variant: 'light-warning',
          title: this.$t('Rut de receptor'),
          content: proof?.recipient_dni || this.$t('Sin información')
        },
        {
          icon: 'UserCheckIcon',
          variant: 'light-warning',
          title: this.$t('Nombre de receptor'),
          content: proof?.recipient_name || this.$t('Sin información')
        },
        {
          icon: 'CalendarIcon',
          variant: 'light-warning',
          title: this.$t('Fecha'),
          content: proof?.date || this.$t('Sin información')
        }
      ]
      if (proof?.carier_url?.indexOf(',') > -1) {
        this.imgsProof = proof?.carier_url?.split(',')
      } else {
        this.imgsProof = [proof?.carier_url]
      }
    }
  }
}
</script>
