<template>
  <div>
    <div class="mb-1 d-flex justify-content-between">
      <div>
        <b-button v-if="showCreateReturn" class="mr-1" @click="openModal('modalCreateReturn')" variant="warning">{{ $t('Generar devolución') }}</b-button>
        <b-button v-if="showGoReturn" class="mr-1" @click="goReturn()" variant="warning">{{ $t('Ver devolución') }}</b-button>
        <b-button v-if="showChangeStatus" @click="openModal('changeStatusModal')" variant="warning"> <feather-icon icon="ArchiveIcon"/> {{ $t('Cambiar estado') }}</b-button>
      </div>
      <div v-if="showDeliveryAttempts" class="d-flex flex-column align-items-center">
        <span style="font-size:10px; color:#165191; margin-bottom:2px">Intentos de entrega</span>
        <b-avatar id="avatar" square badge badge-top badge-offset="-0.4em" :badge-variant="colorBadge" variant="white" src="/attempts-image.png" size="30px">
          <template #badge>{{qtyBadge}}</template>
        </b-avatar>
      </div>
    </div>
    <table-render
    v-if="!loading.first"
    :rows="rows"
    :schema="schema"
    :show-checkboxes="false"
    :stickyHeader="`calc(100vh - 2.5rem)`"
    id="status_history">
      <template #delivery_status_name="scope">
        <span > {{scope.rowdata.delivery_status_name}} <feather-icon v-if="showIconDeliveryStatus(scope)" size="16" icon="AlertCircleIcon" style="margin-bottom: 2px; color: #ea5455"/> </span>
        <a target="_blank" :href="delivery.delivery.extra_fields.url_live_tracking.value" v-if="hasUrl && (scope.rowdata.delivery_status_id === 5 || scope.rowdata.delivery_status_id === 62)"> <feather-icon icon="MapPinIcon" style="color: #337ab7"/> </a>
      </template>
      <template #created_at="scope">
        {{scope.rowdata.created_country_timezone || scope.rowdata.created_at}}
      </template>
      <template #info="scope">
        {{scope.rowdata.json_data || 'Sin información'}}
      </template>
      <template #comment="scope">
        {{scope.rowdata.comment || 'Sin comentario'}}
      </template>
    </table-render>
    <div class="table-render-skeleton" v-show="loading.first">
      <b-skeleton-table
        :rows="4"
        :columns="schema.length || 4"
        :table-props="{}"
      />
    </div>
    <modals
    id="changeStatusModal"
    :dataModal="modalsStatus"
    @onSend="postStatusChange"
    @show="onShowStatus">
    </modals>
    <modals
    id="modalCreateReturn"
    :dataModal="modalsReturn"
    @onSend="onSend"
    @show="onShowReturn">
    </modals>
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapGetters } from 'vuex'
import Modals from '../Modals.vue'
export default {
  components: { Modals, FeatherIcon },
  name: 'history-status',
  props: {
    showChangeStatus: {type: Boolean, default: true},
    dataAccount: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      schema: [],
      keyTableRender: 0,
      rows: [],
      loading : { first: false },
      modalsReturn: {
        fields: [
          {fieldType: 'FieldInput', label:  this.$t('Correo'), name: 'email', containerClass: 'container-info col-sm-12', validation: 'required' },
          {fieldType: 'FieldSelect', label: this.$t('Courier'), name: 'carrier', clearable: true, containerClass: 'container-info col-sm-12', validation: 'required' }
        ],
        nameButton: 'Generar',
        title: 'Generar devolución',
        form: {},
        loading: false,
        key: 0
      },
      modalsStatus: {
        fields: [
          {fieldType: 'FieldSelect', name: 'status', label: 'Nuevo estado', containerClass: 'col-12 container-info', useLabel: true, clearable: true, change: this.changeOrganization, validation: 'required'},
          {fieldType: 'FieldTextarea', name: 'comment', label: 'Comentario', containerClass: 'col-12 container-info', useLabel: true, clearable: true }
        ],
        nameButton: 'Agregar',
        title: 'Cambiar estado de envío',
        form: {},
        loading: false,
        key: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      delivery: 'getDeliveryDetails',
      carriers: 'getCourierConfiguration',
      generalLoading: 'getLoading'
    }),
    showDeliveryAttempts() {
      return !!this.delivery?.delivery_attempts?.qty
    },
    colorBadge() {
      const color = Object.freeze({
        green: 'success',
        orange: 'warning',
        red: 'danger'
      })
      return color[this.delivery?.delivery_attempts?.color] ?? ''
    },
    qtyBadge() {
      return this.delivery?.delivery_attempts?.qty
    },
    canAccessReturns() {
      return !!this.dataAccount?.legacy_data?.can_access_return
    },
    showCreateReturn() {
      return this.canAccessReturns && ['admin', 'superadmin']?.includes(this.$session.get('cas_user')?.role) && this.delivery?.delivery?.return_delivery_id === null && !this.$session.get('cas_user').special_permissions.includes('lock-return-from-delivery') && this.delivery?.delivery?.delivery_status_id === 10
    },
    showGoReturn() {
      return this.canAccessReturns && !!this.delivery?.delivery?.return_delivery_id && this.delivery?.delivery?.delivery_status_id === 10
    },
    hasUrl() {
      return !!this.delivery?.delivery?.extra_fields?.url_live_tracking?.value
    },
    statuses() {
      if (this.delivery?.delivery?.pudo) {
        return this.delivery?.statuses?.pudo?.map((status) => ({ text: status?.name, id: status?.id, ...status }))
      }
      return this.delivery?.statuses?.home?.map((status) => ({ text: status?.name, id: status?.id, ...status })) 
    },
    statusLogs() {
      if (this.delivery?.delivery?.delivery_status_log && typeof this.delivery?.delivery?.delivery_status_log === 'object') {
        return Object.values(this.delivery?.delivery?.delivery_status_log)
      }
      return this.delivery?.delivery?.delivery_status_log
    }
  },
  watch: {
    generalLoading: {
      handler () {
        this.loading.first = !!this.generalLoading.getDeliveryDetails
        this.modalsReturn.loading = !!this.generalLoading.postCreateReturn
        this.modalsStatus.loading = !!this.generalLoading.postChangeStatusDelivery
      },
      deep: true
    },
    statusLogs(val) {
      this.rows = val?.map(el => ({
        ...el,
        user_e: el.changed_by || el.user_email || 'Sin información'
      }))
    }
  },
  mounted() {
    this.setInitialData()
    this.rows = this.statusLogs?.map(el => ({
      ...el,
      user_e: el.changed_by || el.user_email || 'Sin información'
    }))
  },
  methods: {
    setInitialData () {
      this.schema = [
        {label: 'Estado', sortable: true, key: 'delivery_status_name', useSlot: true},
        {label: 'Fecha', sortable: true, key: 'created_at', useSlot: true},
        {label: 'Comentario', sortable: true, key: 'comment', useSlot: true},
        {label: 'Detalle', sortable: true, key: 'info', useSlot: true},
        {label: 'Usuario', sortable: true, key: 'user_e'}
      ]
    },
    showIconDeliveryStatus(scope) {
      return [4, 112].includes(scope?.rowdata?.delivery_status_id) || scope?.rowdata?.delivery_status_trouble || scope?.rowdata?.comment?.includes('SKU no enviados a fulfillment')
    },
    goReturn() {
      this.$router.push({ name: 'return-details', params: { id: this.delivery?.delivery?.return_delivery_id } })
    },
    openModal(name) {
      this.$bvModal.show(name)
    },
    fetchCreateReturn(form) {
      const data = {
        name: 'postCreateReturn',
        params: { delivery_id: this.$route.params.id },
        queryParams: { ...form }
      }
      this.$store.dispatch('fetchService', data)
    },
    postStatusChange(form) {
      const data = { 
        name: 'postChangeStatusDelivery',
        params: { delivery_id: this.delivery?.delivery?.id },
        queryParams: { id: form?.status?.id, comment: form?.comment },
        onSuccess: () => {
          this.$emit('getDelivery')
          this.$bvModal.hide('changeStatusModal')
        },
        onError: () => {
          this.modalsStatus.loading = false
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    onShowReturn() {
      const { fields, form } = this.modalsReturn
      form.email = this.delivery?.delivery?.email
      fields.find((field) => field.name === 'carrier').options = this.carriers?.map((carrier) => ({ id: carrier.carrier_code, text: carrier.carrier_name }))
      this.modalsReturn.key++
    },
    onSend(form) {
      const carrier = this.carriers.find((field) => field.carrier_code === form.carrier.id)
      const data = { 
        name: 'postCreateReturn',
        params: { delivery_id: this.$route.params.id },
        queryParams: { email: form.email, carrier},
        onError: () => this.modalsReturn.loading = false
      }
      this.$store.dispatch('fetchService', data)
    },
    onShowStatus() {
      const { fields } = this.modalsStatus
      fields.find((field) => field.name === 'status').options = this.statuses
      this.modalsStatus.key++
    }
  }
}
</script>

<style>
  .table-render-skeleton {
    overflow-x: hidden !important;
  }
  #avatar > span > img {
    width: 25px;
    height: 25px;
  }
</style>