<template>
  <b-modal :static="true"
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Comprobante de entrega')"
    :ok-title="$t('Cerrar')"
    size="lg"
    centered modal-class="dialog-1200 custom-dialog"
    ok-variant="outline-secondary"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    no-footer
    @show="onShow"
  >
    <div v-if="loading" class="d-flex justify-content-center align-items-center">
      <b-spinner type="grow" style="width: 3rem; height: 3rem;"/> 
    </div>
    <div v-if="!!url">
      <b-embed
        type="iframe"
        aspect="16by9"
        allowfullscreen
        name="myFrame"
        id="myFrame"
      >
      </b-embed>
      <form id="myForm" ref="myForm" target="myFrame" :action="url" :method="method">
        <a id="myInput" ref="myInput" type="submit" />
      </form>
    </div>
  </b-modal>
</template>
<script>
import { BEmbed } from 'bootstrap-vue'
export default {
  components: { BEmbed },
  name: 'widget-modal-delivery',
  inheritAttrs: false,
  props: {
    widget: { type: Object, default: () => ({}) },
    showForm: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    url() {
      return this.widget.url ?? ''
    },
    method() {
      return this.widget?.method?.toLowerCase() ?? ''
    },
    isPost() {
      return this.method === 'post'
    }
  },
  methods: {
    witgetPost() {
      this.loading = true
      const callservice = () => { 
        setTimeout(() => {
          this.$refs.myForm.submit()
          this.loading = false
        }, 500)
      }
      callservice()
    },
    onShow() {
      this.witgetPost()
    }
  }
}
</script>