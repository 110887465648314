<template>
  <div>
    <b-modal
      v-bind="$attrs"
      v-on="$listeners"
      size="lg"
      centered
      :modal-class="`modal-${titleColor} dialog-1200 custom-dialog`"
      no-close-on-esc no-close-on-backdrop
      ok-variant="warning"
      ok-only
      :title="title"
      >
    <div class="col-md-12">
	<h2 style="color: #2e6c80; text-align: center;"><span style="color: #ff0000;">Recuerda que debes imprimir tu etiqueta en la opci&oacute;n <strong>"Imprimir"</strong> dentro de "<strong>Envios-&gt;Crear Env&iacute;o</strong>"</span></h2>
    </div>
      <iframe :src="urlSize"></iframe>
    </b-modal>
    
  </div>
</template>
<script>
export default {
  name: 'modal-label',
  props: { 
    url: { type: String },
    title: { type: String, default: 'Detalle'},
    titleColor: { type: String, default: 'warning'}
  },
  computed: {
    urlSize() {
      return `${this.url}#page=1&zoom=150`
    }
  }
}
</script>

<style lang="scss">
  #modalLabel iframe {
    width: 100%;
    height: calc(100vh - 13rem);
  }
</style>