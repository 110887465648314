<template>
  <div class="pb-1">
    <!-- info header -->
    <b-card class="mb-1">
      <b-row class="justify-content-center">
        <b-col cols="6" md="4" lg="2" xl="2" v-for="(info, index) in mediaInfo" :key="index">
          <media-info v-bind="info" v-if="!loading.delivery">
            <template #transit>
              <h6 class="font-weight-bold mb-0 font-small-2">
                {{mediaInfoTransit}} <feather-icon v-if="showMediaInfoTransitIcon" id="icon-transit" style="color: #23c6c8" icon="AlertCircleIcon"></feather-icon>
                <b-tooltip v-if="showMediaInfoTransitIcon" target="icon-transit" :title="mediaInfoTransitIcon"></b-tooltip>
              </h6>
            </template>
            <template #summary>
              <h6 class="font-weight-bold mb-0 font-small-2">
                <span v-if="showSummariesUrl && isUserFreemium">Nº {{delivery.delivery.delivery_label_print_id}}</span>                
                <a v-if="showSummariesUrl && !isUserFreemium" :href="urlSummaries" target="_blank">Nº {{delivery.delivery.delivery_label_print_id}}</a>
                <span v-if="showSummaryPrintRow"> (FILA: {{delivery.delivery.delivery_label_print_row}})</span>
                <span v-if="!showSummariesUrl">No disponible</span>
              </h6>
            </template>
          </media-info>
        </b-col>
        <div class="row" v-if="loading.delivery">
          <b-col cols="6" md="4" lg="2" xl="2" v-for="index in 6" :key="index" class="d-flex justify-content-center">
            <b-skeleton type="avatar"></b-skeleton>
            <div class="ml-1">
              <b-skeleton width="70px"></b-skeleton>
              <b-skeleton width="30px"></b-skeleton>
            </div>
          </b-col>
        </div>
      </b-row>
      <b-row>
        <b-col cols="12" class="mt-1" v-if="!loading.delivery">
          <timeline :timeline.sync="timeline" icon="TruckIcon" type="lineal" color="warning" :showIconAllActive="false">
            <template #iconSlot>
              <a :href="urlLiveTracking" v-if="showMarketTimeline" target="_blank">
                <feather-icon  icon="MapPinIcon" size="2x" style="color: #337ab7;margin-left:5px"/>
              </a>
            </template>
          </timeline>
        </b-col>
        <div class="row w-100 justify-content-center mt-2" v-if="loading.delivery">
          <b-col class="d-flex justify-content-center" cols="6" md="3" lg="2" v-for="index in 4" :key="'skeleton'+index">
            <div>
              <div class="d-flex justify-content-center">
                <b-skeleton type="avatar" width="50px" height="50px"></b-skeleton>
              </div>
              <b-skeleton class="mt-1" width="80px"></b-skeleton>
            </div>
          </b-col>
        </div>
      </b-row> 
    </b-card>
    <!-- buttons -->
    <div class="my-1 d-flex justify-content-end">
      <b-button v-if="showForceTrancking" @click="fetchTracking('getForceTracking')" variant="danger" class="mr-1">{{$t("Forzar tracking")}} <span v-if="loading.forceTracking"><b-spinner small/> </span></b-button>
      <b-button v-if="showCanRejectInOtherCarrier" @click="openModal('modalChangeCarrier')" variant="primary" class="mr-1">{{$t("Cambiar de courier")}}</b-button>
      <b-button v-if="showDeleteTracking" @click="fetchTracking('deleteTracking')" variant="info" class="mr-1">{{$t("Eliminar tracking")}} <span v-if="loading.deleteTracking"><b-spinner small/> </span></b-button>
      <b-button v-if="showDispatchDelivery" @click="fetchDispatchDelivery()" variant="warning" class="mr-1">{{$t("Reinyectar envío")}} <span v-if="loading.dispatchDelivery"><b-spinner small/> </span></b-button>
      <b-button v-if="showCancelDelivery" @click="fetchTracking('deleteDeliveryPlat')" variant="danger" class="mr-1">{{$t("Cancelar envío")}} <span v-if="loading.deleteDeliveryPlat"><b-spinner small/> </span></b-button>
      <b-button v-if="showButtonProof" variant="primary" @click="proofOfDelivery()">{{$t("Ver comprobante de entrega")}}</b-button>
      <b-button v-if="showDuplicateDelivery" variant="primary" @click="fecthDuplicateDelivery()">{{$t("Duplicar envío")}}</b-button>
    </div>

    <!-- links -->
    <div class="my-1 d-flex justify-content-end">
      <b-link v-if="showMultiPackage" class="ml-1" @click="openModal('modalMultiPackage')">{{$t("Transformar a multibulto")}}</b-link>
      <b-link v-if="showLabel" class="ml-1" @click="openModal('modalLabel')">{{$t("Etiqueta")}}</b-link>
      <b-link v-if="showResendEmail" class="ml-1" @click="fetchResendEmail()">{{$t("Reenviar email")}}</b-link>
    </div>

    <!-- tabs -->
    <b-card>
      <tabs
      :warehouses.sync="services.warehouses"
      :carriers.sync="services.carriers"
      @getDelivery="getDelivery()"
      :showChangeStatus="showChangeStatus"
      :dataAccount.sync="dataAccount"
      ></tabs>
    </b-card>
    <div>
      <widget-modal-delivery
      id="widgetModal"
      :widget="widgetInfo"
      :showForm="showIframeForm"
      ></widget-modal-delivery>
    </div>
    <div>
      <proof-modal
      id="proofModal"
      :proof="delivery_proof"
      ></proof-modal>
    </div>
    <modal-label
    id="modalLabel" 
    :title="$t('Etiqueta')" 
    :url.sync="showUrl" 
    titleColor="dark"
    ></modal-label>
    <modals
    id="modalChangeCarrier"
    :dataModal="modalCarrier"
    @onSend="fetchChangeCarrier"
    @show="onShowChangeCarrier"
    ></modals>
    <modals
    id="modalMultiPackage"
    :dataModal="modalMultipackage"
    @onSend="fecthChangeMultipackage"
    @show="onShowMultipackage"
    ></modals>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeliveriesListService from '../deliveriesList.service'
import Timeline from '@/views/components/custom/timeline/Timeline.vue'
import Tabs from '@/views/modules/deliveries/list-deliveries/detail-delivery/Tabs.vue'
import ProofModal from '@/views/modules/web-tracking/ProofModal.vue'
import WidgetModalDelivery from './WidgetModalDelivery.vue'
import { BLink } from 'bootstrap-vue'
import Modals from './Modals.vue'
import ModalLabel from './ModalLabel.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { environment } from '@/environments/environment'

export default {
  components: { Timeline, Tabs, WidgetModalDelivery, ProofModal, BLink, Modals, ModalLabel, FeatherIcon },
  data () {
    return {
      mediaInfo: [],
      currentDelivery: {},
      statusTrackings: [],
      countries: [],
      organizations: [],
      addresses: [],
      statusAllowEditDelivery: false,
      widgetInfo: {},
      delivery_proof: {},
      loading: {
        delivery: false,
        forceTracking: false,
        deleteTracking: false,
        deleteDeliveryPlat: false,
        dispatchDelivery: false
      },
      deliveriesListService: new DeliveriesListService(this),
      timeline: [],
      services: {
        warehouses: [],
        carriers: []
      },
      groups: {
        CREATED : {
          status: ['CREATED', 'PENDING', 'Orden en preparación']
        },
        PICK_UP_CARRIER : {
          status: ['PICK_UP_CARRIER', 'IN_ORIGIN', 'Retirado por operador', '*']
        },
        DISPATCHED : {
          status: ['DISPATCHED', 'PARTIALLY_DELIVERED', 'En reparto']
        },
        DELIVERED_PUDO : {
          status: ['DELIVERED_PUDO', 'Disponible para retiro']
        },
        DELIVERED : {
          status: ['DELIVERED', 'DELIVERED_DOM', 'PICKED_UP', 'Entregado']
        }
      },
      deliveryTypes: Object.freeze({
        'pasarela-pro': 'Pasarela Pro',
        'distribucion': 'Distribución',
        'pasarela': 'Pasarela'
      }),
      modalMultipackage: {
        fields: [
          {fieldType: 'FieldInput', name: 'n_package', label: 'Cantidad de bultos', containerClass: 'col-12 container-info', useLabel: true, clearable: true, validation: 'required', type: 'number'}
        ],
        nameButton: 'Actualizar',
        isEnableButton: true,
        title: 'Cambiar a multibulto',
        form: {},
        loading: false,
        key: 0
      },
      modalCarrier: {
        fields: [
          {fieldType: 'FieldSelect', name: 'carrier', label: 'Nuevo courier', containerClass: 'col-12 container-info', useLabel: true, clearable: true, change: this.changeOrganization, validation: 'required'}
        ],
        nameButton: 'Reinyectar',
        isEnableButton: true,
        title: 'Cambiar de courier',
        form: {},
        loading: false,
        key: 0
      },
      dataAccount: {}
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      delivery: 'getDeliveryDetails',
      warehouse: 'getWarehouseCompanies',
      generalLoading: 'getLoading',
      cancelable: 'getIsCancelable'
    }),
    isStoreProfile() {
      return !!this.dataAccount?.legacy_data?.store_profile
    },
    isContactProfile() {
      return !!this.dataAccount?.legacy_data?.contact_profile
    },
    employee_company_id() {
      return this.dataAccount?.legacy_data?.employee_company_id
    },
    dubai_organization_id() {
      return this.dataAccount?.legacy_data?.dubai_organization_id
    },
    showLabelEvaluate() {
      if ((((this.isUserEcommerce || this.isUserSeller) && ([100476, 100482, 154098].includes(this.employee_company_id) || this.isUserMarketplace) && [1162, 65563].includes(this.employee_company_id)) || [4612, 3048, 3169, 3310, 3311, 4184, 2271, 6860, 7048, 7981, 9922, 6734, 1312, 2606, 65563].includes(this.dubai_organization_id) || this.isUserAdmin) && this.delivery?.delivery?.delivery_status_id !== 12) {
        return true
      }
      if (!!Array.isArray(this.delivery?.delivery?.delivery_status_log)) {
        const status_find = this.delivery?.delivery?.delivery_status_log?.map(status => [26, 9, 6].includes(status.delivery_status_id) && this.delivery?.delivery?.delivery_status_id !== 12)
        if (status_find.includes(true)) return true
      }
      return false
    },
    allowSecondaryReference() {
      return (this.delivery.delivery.carrier.code === 'FDX' || this.delivery.delivery.carrier.code === 'FDXM' || this.delivery.delivery.carrier.code === 'EST') &&
        this.delivery.delivery?.extra_fields && this.delivery.delivery.extra_fields.secondary_reference && this.delivery.delivery.extra_fields?.secondary_reference?.value
    },
    isUserAdmin() {
      return ['admin', 'superadmin'].includes(this.mySession?.role)
    },
    isUserPudo() {
      return ['pudo'].includes(this.mySession?.role)
    },
    isUserMarketplace() {
      return ['marketplace'].includes(this.mySession?.role)
    },
    isUserEcommerce() {
      return ['ecommerce'].includes(this.mySession?.role)
    },
    isUserSeller() {
      return ['seller'].includes(this.mySession?.role)
    },
    isUserCustom() {
      return ['custom'].includes(this.mySession?.role)
    },
    isUserFreemium() {
      return ['ecommerce'].includes(this.mySession?.role) && this.mySession?.user_access?.company_category_id === 2
    },
    showDeleteTracking() {
      return this.isUserAdmin && this.delivery.delivery?.delivery_status_id >= 5
    },
    showForceTrancking() {
      return ['admin'].includes(this.mySession?.role)
    },
    showDispatchDelivery() {
      return this.delivery?.canDispatch
    },
    isCancelable() {
      if (this.cancelable && this.cancelable.length > 0) {
        const { shipper_cancel, cancel_delivery, cancelable_statuses } = this.cancelable[0]
        return cancel_delivery || shipper_cancel ? cancelable_statuses.includes(this.delivery.delivery.delivery_status.id) : false
      } else {
        return false
      }
    },
    showCancelDelivery() {
      return !!this.delivery.delivery?.carrier?.customization?.cancel_delivery && this.isCancelable
    },
    showCanRejectInOtherCarrier() {
      return this.delivery?.canReinjectInOtherCarrier
    },
    showButtonProof() {
      return !!this.delivery?.delivery?.delivery_proof || !!this.delivery?.widgetInfo
    },
    showModalDeliveryProof() {
      return !!this.delivery?.delivery?.delivery_proof
    },
    showModalWidgetInfo() {
      return !!this.delivery?.delivery?.delivery_proof
    },
    showLabel() {
      if (this.isUserFreemium) return true
      return !!this.delivery?.delivery?.label && this.showLabelEvaluate && !this.isUserPudo
    },
    showUrl() {
      return `${this.delivery?.delivery?.label}`
    },
    showDuplicateDelivery() {
      return !this.isUserPudo && this.delivery?.delivery?.delivery_status_id === 53 && this.delivery?.delivery?.carrier_id === 21 && !!this.delivery?.delivery?.reference_2
    },
    showMultiPackage() {
      if (this.isUserFreemium) return false
      return this.delivery?.delivery?.carrier?.customization?.multi_package && (this.delivery?.delivery?.delivery_status_id === 5 || this.delivery?.delivery?.delivery_status_id === 26) && this.delivery?.delivery?.n_packages === 1 && this.delivery?.canChangePackages
    },
    showResendEmail() {
      return this.isUserAdmin  && !!this.delivery?.delivery?.pudo
    },
    getDeliveryType() {
      if (this.delivery?.delivery?.contract_type && !!this.deliveryTypes[this.delivery?.delivery?.contract_type]) return this.deliveryTypes[this.delivery?.delivery?.contract_type] ?? 'Pasarela'
      return this.delivery.delivery?.easypoint === 1 ? 'Distribución' : 'Pasarela'
    },
    showSummariesUrl() {
      return !!this.delivery?.delivery?.delivery_label_print_id
    },
    urlSummaries() {
      return `/summaries/${this.delivery?.delivery?.delivery_label_print_id}`
    },
    showSummaryPrintRow() {
      return !!this.delivery?.delivery?.delivery_label_print_row && !Array.isArray(this.delivery?.delivery?.delivery_label_print_row)
    },
    showChangeStatus() {
      if (this.isUserAdmin) return true
      return this.checkDeliveryPermissions() && this.checkUserSpecialPermissions('manage-status-deliveries')
    },
    mediaInfoDelivery() {
      const { delivery } = this.delivery
      let content = !!delivery?.imported_id ? `Ref: ${delivery?.imported_id}` : ''
      if (delivery?.tracking_number) content += `<br>OT: ${delivery?.tracking_number} `
      if (this.allowSecondaryReference) content += `<br>Referencia 2: ${delivery?.extra_fields?.secondary_reference?.value}`
      if (!this.allowSecondaryReference && delivery?.extra_fields && delivery?.extra_fields['reference_2']) content += `<br>Referencia 2: ${delivery?.extra_fields['reference_2']?.value}`
      return content
    },
    mediaInfoTransit() {
      return this.delivery?.delivery?.transit_days ?? this.$t('Sin información')
    },
    mediaInfoTransitIcon() {
      if (this.delivery?.delivery?.transit_days_source === 'rule') return 'Por regla enviame'
      if (this.delivery?.delivery?.transit_days_source === 'coverage') return 'Por cobertura courier'
      return ''
    },
    showMediaInfoTransitIcon() {
      return !!this.delivery?.delivery?.transit_days_source
    },
    mediaInfoTitleSummary() {
      let content = 'Manifiesto'
      if (this.delivery?.delivery?.summary_status) content += `${this.delivery?.delivery?.summary_status}`
      return content
    },
    showIframeForm() {
      if (!this.delivery) {
        return false
      } else return ['get', 'post'].includes(this.delivery?.widgetInfo?.method?.toLowerCase())
    },
    showMarketTimeline() {
      return !!this.delivery?.delivery?.extra_fields?.url_live_tracking?.value && !['SHFF'].includes(this.delivery?.delivery?.carrier?.code)
    },
    urlLiveTracking() {
      return this.delivery?.delivery?.extra_fields?.url_live_tracking?.value
    }
  },
  mounted() {
    this.getDelivery()
  },
  watch: {
    '$route.params.id'() {
      this.getDelivery()
    },
    warehouse(val) {
      this.services.warehouses = val?.rows?.map(el => ({...el, text: el.name})) ?? []
    },
    generalLoading: {
      handler () {
        this.loading.delivery = !!this.generalLoading.getDeliveryDetails
        this.modalMultipackage.loading = !!this.generalLoading.putChangeMultipackage
        this.modalCarrier.loading = !!this.generalLoading.putChangeCarrier
        this.loading.forceTracking = !!this.generalLoading.getForceTracking
        this.loading.deleteTracking = !!this.generalLoading.deleteTracking
        this.loading.deleteDeliveryPlat = !!this.generalLoading.deleteDeliveryPlat
        this.loading.dispatchDelivery = !!this.generalLoading.postDispatchDelivery
      },
      deep: true
    },
    mySession() {
      this.getValuesAccount()
    }
  },
  methods: {
    getValuesAccount() {
      this.$root.$data.db.collection('accounts').doc(this.mySession?.id).onSnapshot((doc) => {
        if (doc.exists) {
          this.dataAccount = {...doc.data(), id: doc.id}
        }
      })
    },
    setInitialData(newValue) {
      const services = [
        { name: 'getCourierConfiguration', params: {company_id: newValue?.delivery?.company_id }, queryParams: {limit: 250, exclude_flash: 0}, onSuccess: (res) => this.services.carriers = res?.rows?.map(el => ({...el, text: el.carrier_name, id: el.carrier_id})) ?? [], hideAlert: true},
        { name: 'getWarehouseCompanies', params: {company_id: newValue?.delivery?.company_id }},
        { name: 'getIsCancelable', params: {carrier_code: newValue?.delivery?.carrier?.code }}
      ]
      if (newValue?.delivery?.country?.code?.toLowerCase() !== 'ar') services.push({ name: 'getLevels', params: { country: newValue?.delivery?.country?.code?.toLowerCase()}, hideAlert: true })
      else services.push({ name: 'getPlacesByCountry', params: {country_id: newValue.delivery?.country_id }})
  
      if (newValue?.delivery?.country?.code?.toLowerCase() === 'mx') {
        const zip_code = JSON.parse(newValue?.delivery?.place_name?.customization)?.zip_code
        services.find(service => service.name === 'getLevels').queryParams = {zip_code}
      }
      this.$store.dispatch('fetchMultipleServices', { services })
      this.generateTimeline(newValue)
      this.setMediaInfo(newValue?.delivery)
      this.widgetInfo = this.delivery?.widgetInfo ?? {}
      this.delivery_proof = this.delivery?.delivery?.delivery_proof ?? {}
    },
    fetchChangeCarrier(form) {
      const data = {
        name: 'putChangeCarrier',
        queryParams: {
          deliveries: [Number(this.$route.params.id)],
          carrier_code: form.carrier?.code
        },
        onSuccess: () => { this.closeModal('modalChangeCarrier'); this.getDelivery() },
        onError: () => this.modalCarrier.loading = false
      }
      this.$store.dispatch('fetchService', data)
    },
    fetchDispatchDelivery() {
      if (this.delivery?.delivery?.delivery_status_id !== 4 && this.delivery?.delivery?.delivery_status_id !== 1) {
        return
      }
      const data = {
        name: 'postDispatchDelivery',
        queryParams: {deliveries: [this.$route.params.id]},
        onSuccess: () => this.getDelivery(),
        onError: () => this.loading.dispatchDelivery = false
      }
      this.$store.dispatch('fetchService', data)
    },
    fetchTracking(name) {
      const data = {
        name,
        params: {
          delivery_id: this.$route.params.id
        },
        onSuccess: () => this.getDelivery(),
        onError: () => {
          this.loading.forceTracking = false
          this.loading.deleteTracking = false
          this.loading.deleteDeliveryPlat = false
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    fecthDuplicateDelivery() {
      const data = {
        name: 'postDuplicateDelivery',
        params: {
          delivery_id: this.$route.params.id
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    fecthChangeMultipackage(form) {
      const data = {
        name: 'putChangeMultipackage',
        params: { delivery_id: this.$route.params.id },
        queryParams: { n_package: Number(form?.n_package) },
        onSuccess: () => { this.closeModal('modalMultiPackage'); this.getDelivery() },
        onError: () =>  this.modalMultipackage.loading = false
      }
      this.$store.dispatch('fetchService', data)
    },
    fetchResendEmail() {
      const data = {
        name: 'getResendEmail',
        params: { delivery_id: this.$route.params.id }
      }
      this.$store.dispatch('fetchService', data)
    },
    getDelivery () {
      const data = {
        name: 'getDeliveryDetails',
        params: {
          delivery_id: this.$route.params.id
        },
        onSuccess: (data) => this.setInitialData(data.data)
      }
      this.$store.dispatch('fetchService', data)
      this.getValuesAccount()
    },
    generateTimeline (data) {
      const timeaux = [
        { stage_code: 'CREATED', title: 'Listo para despacho', date: null, active: false, id:0 },
        { stage_code: 'PICK_UP_CARRIER', title: 'Retirado / Planta origen', date: null, active: false, id: 1},
        { stage_code: 'DISPATCHED', title: 'En camino', date: null, active: false, id: 2},
        { stage_code: 'DELIVERED_PUDO', title: 'En planta regional', date: null, active: false, id: 3},
        { stage_code: 'DELIVERED', title: 'En reparto', date: null, active: false, id: 4},
        { stage_code: 'DELIVERED', title: 'Entregado', date: null, active: false, id: 5}
      ]

      let indexState = 0
      let indexStateId4 = 0
      const indexStateId2 = timeaux.findIndex(el => el.id === 2)

      if (indexStateId2 !== -1 && data?.delivery?.flow_type !== 'expanded') timeaux.splice(indexStateId2, 2)

      switch (data.delivery.delivery_status_id) {
      case 30:
        indexState = timeaux.findIndex(el => el.id === 5)
        indexStateId4 = timeaux.findIndex(el => el.id === 4)
        timeaux[indexState].title = 'Devolución entregada'
        timeaux[indexState - 1].title = 'Disponible para retiro'
        break
      case 6:
        indexState = timeaux.findIndex((el) => el.id === 1)
        break
      case 7:
        indexState = timeaux.findIndex((el) => el.id === 2)
        if (data?.delivery?.flow_type !== 'expanded') indexState = 1
        break
      case 8:
        indexState = timeaux.findIndex((el) => el.id === 3)
        if (data?.delivery?.flow_type !== 'expanded') indexState = 1
        break
      case 9:
        indexState = timeaux.findIndex(el => el.id === 4)
        break
      case 10:
        indexState = timeaux.findIndex(el => el.id === 5)
        break
      case 11:
        indexState = timeaux.findIndex(el => el.id === 4)
        timeaux[indexState].title = 'Disponible para retiro'
        timeaux[indexState + 1].title = 'Retirado'
        break
      case 13:
        indexState = timeaux.findIndex(el => el.id === 5)
        timeaux[indexState].title = 'Retirado'
        timeaux[(indexState - 1)].title = 'Disponible para retiro'
        break
      case 16:
        indexState = timeaux.findIndex(el => el.id === 1)
        break
      case 17:
        indexState = timeaux.findIndex(el => el.id === 4)
        timeaux[indexState].title = 'En reparto'
        timeaux[(indexState + 1)].title = 'Entregado'
        break
      case 26:
        indexState = timeaux.findIndex(el => el.id === 0)
        break
      case 29:
        indexState = timeaux.findIndex(el => el.id === 5)
        timeaux[(indexState - 1)].title = 'Disponible para retiro'
        timeaux[(indexState - 1)].msj = 'Pronto a expirar'
        timeaux[indexState].title = 'Expirado'
        break
      case 50:
        indexState = timeaux.findIndex(el => el.id === 4)
        timeaux[indexState].title = 'En reparto'
        timeaux[(indexState + 1)].title = 'Entregado'
        break
      case 54:
        indexState = timeaux.findIndex(el => el.id === 4)
        timeaux[indexState].title = 'Disponible para retiro'
        timeaux[(indexState + 1)].title = 'Retirado'
        break
      case 56:
        indexState = timeaux.findIndex(el => el.id === 4)
        timeaux[indexState].title = 'Disponible para retiro'
        timeaux[(indexState + 1)].title = 'Retirado'
        break
      default:
        break
      }
      timeaux[indexState].active = true

      this.timeline = timeaux
        ?.map((el, i) => {
          if (i <= indexState) el.active = true
          return { ...el}
        })
    },
    setMediaInfo (data) {
      this.mediaInfo = [
        {
          icon: 'TruckIcon',
          customClass: '',
          variant: 'light-light',
          title: `Envío(${data.carrier_name})`,
          content: this.mediaInfoDelivery
        },
        {
          icon: 'MapPinIcon',
          customClass: '',
          variant: 'light-light',
          title: 'Destino',
          content: data.address?.place_name ?? data.pudo?.place_name ?? 'No disponible'
        },
        {
          icon: 'ClockIcon', 
          customClass: '', 
          variant: 'light-light', 
          title: 'Tiempo de transito', 
          useSlot: true,
          keySlot: 'transit'
        },
        {
          icon: 'CalendarIcon', 
          customClass: '', 
          variant: 'light-light', 
          title: 'Fecha de compromiso', 
          content: data?.deadline_date_formatted ?? 'No disponible'
        },
        !!data?.delivery_status && {
          icon: 'FileIcon', 
          customClass: '', 
          variant: 'light-light', 
          title: this.mediaInfoTitleSummary,
          useSlot: true,
          keySlot: 'summary'
        },
        !!data?.tracking_number && {
          icon: 'SendIcon', 
          customClass: '', 
          variant: 'light-light', 
          title: 'Envío tipo',
          content: this.getDeliveryType
        }
      ].filter((field) => field)
    },
    setStatusAllowEditDelivery (data) {
      if (data.order.status.status_id === 1 || data.order.status.status_id === 4) {
        this.statusAllowEditDelivery = true
      } else this.statusAllowEditDelivery = false
    },
    openModal(name) {
      this.$bvModal.show(name)
    },
    closeModal(name) {
      this.$bvModal.hide(name)
    },
    proofOfDelivery() {
      if (this.delivery?.delivery?.tracking_web_url) window.open(this.delivery?.delivery?.tracking_web_url, '_black')
      if (this.delivery?.widgetInfo?.method.toLowerCase() === 'get') {
        this.$bvModal.show('widgetModal')
      } else if (this.delivery?.widgetInfo?.method.toLowerCase() === 'post') {
        this.$bvModal.show('widgetModal')
      }
      if (this.delivery?.delivery?.delivery_proof) this.$bvModal.show('proofModal')
    },
    showWidget() {
      let show = false
      if (this.delivery?.delivery?.carrier?.customization?.tracking_web || this.delivery?.delivery?.carrier?.customization?.widget) {
        show = true
        if (this.delivery?.delivery?.carrier?.code || (
          (this.delivery?.delivery?.easypoint || this.delivery?.delivery?.marketplace_id === 1162) && this.delivery?.delivery?.carrier?.code === 'BLX'
        )) {
          show = this.delivery?.delivery?.extra_fields['beecode']
        }
      }
      return show
    },
    checkDeliveryPermissions() {
      if (!this.delivery?.delivery) return false
      // $user->role_id == 2
      if (this.isUserEcommerce) {
        if (this.delivery?.delivery?.marketplace_id !== this.delivery?.delivery?.company_id) return false
      }
      // if($user->role_id == 4 || $user->storeProfile())
      if (this.isUserMarketplace || this.isStoreProfile) {
        if (this.delivery?.delivery?.employee || this.delivery?.delivery?.marketplace_id !== this.delivery?.delivery?.company_id) return false
      }
      // $user->role_id == 5
      if (this.isUserCustom) {
        if (this.delivery?.delivery?.employee || (this.delivery?.delivery?.marketplace_id !== this.delivery?.delivery?.company_id && this.delivery?.delivery?.company_id !== this.employee_company_id)) return false
      }

      if (this.isStoreProfile || this.isContactProfile) {
        if (this.delivery?.delivery?.delivery_status_id !== 10) {
          return false
        }
      }
      return true
    },
    checkUserSpecialPermissions(permission) {
      return !!this.mySession?.special_permissions?.includes(permission)
    },
    onShowMultipackage() {
      const { form } = this.modalMultipackage
      form.n_package = this.delivery?.delivery?.n_packages
      this.modalMultipackage.key++
    },
    onShowChangeCarrier() {
      const { fields } = this.modalCarrier
      fields.find((field) => field.name === 'carrier').options = this.delivery?.carrierCompanies?.map((carrier) => ({ ...carrier, text: carrier?.name })) ?? []
      this.modalCarrier.isEnableButton = this.delivery?.delivery.delivery_status_id !== 5 && this.delivery?.delivery.delivery_status_id !== 26
    }
  }
}
</script>