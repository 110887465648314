<template>
  <div class="ml-1">
    <b-list-group class="w-50 mt-1" v-if="showItems">
      <h3 class="mb-1">Logs relevantes de la creación del envío</h3>
      <b-list-group-item v-for="item in keys" :key="item">        
        <b-row>
          <b-col cols="8" sm="6">
            <b-badge variant="success">{{item}}</b-badge>
          </b-col>
          <b-col cols="4" sm="6">
            <a v-if="!validateUrl(creation[item][0])" :href="replaceRegisterUrl(creation[item][0])" target="_blank">Ver registro
              <feather-icon
              icon="ArchiveIcon"
              size="14"
              />
            </a>
            <span v-if="validateUrl(creation[item][0])">{{creation[item][0]}}</span>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <div v-if="!showItems" class="text-center py-2">
      <h3>No existen logs para mostrar</h3>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'advance',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      delivery: 'getDeliveryDetails'
    }),
    creation() {
      return this.delivery?.delivery?.creation ?? {}
    },
    keys() {
      return this.delivery?.delivery?.creation ? Object?.keys(this.delivery?.delivery?.creation) : []
    },
    showItems() {
      return this.keys.length > 0
    }
  },
  methods: {
    replaceRegisterUrl(item) {
      return item.replace(/storage\.googleapis\.com\/(carrier-deliveries|log-deliveries-santiago)/g, 'storage.cloud.google.com/$1')
    },
    validateUrl(item) {
      return ['Sin'].includes(item.substring(0, 3))
    }
  }
}
</script>
